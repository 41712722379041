.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  width: 100%;
  height: 90%;
  max-width: 1500px;
  object-fit: contain;
  position: absolute;
}

.svg.overlay {
  pointer-events: none;
}

.main {
  margin-right: 620px;
}

img {
  image-rendering: -webkit-optimize-contrast;
}